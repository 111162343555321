

  .center {
    width: 180px;
    height: 60px;
    position: absolute;
    right: 0;
    left: 0;
    margin: 5px auto;
  
  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-width: 5px;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    /* background: #4F95DA; */
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 700;
  }