@tailwind base;
@tailwind components;
@tailwind utilities;
/* Inside Home.module.css or a global CSS file */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Della+Respira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body,* {
  margin: 0;
  padding:0;
  font-family:'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}

/* .mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border:1px solid gray;
  background-color:lightblue;
} */

.mapboxgl-ctrl.mapboxgl-ctrl-attrib{
  display: none !important;
}


